import React, { useEffect, useState } from "react";
import { useWindowSize } from "@hooks";
import Image from "./Image/ImageWrap";
import imageProps from "./Image/propTypes";

const Prefooter = ({ image }) => {
  const [rendered, setRendered] = useState(false);
  const windowSize = useWindowSize();
  const width = Math.min(windowSize.innerWidth - 32, 1260);
  const height = (width * image.height) / image.width;

  useEffect(() => {
    setRendered(true);
  }, []);

  if (!rendered) return null;

  return (
    <div className="flex justify-center">
      <div style={{ width, height }}>
        <Image image={image} objectFit="contain" />
      </div>
    </div>
  );
};

Prefooter.propTypes = {
  image: imageProps.props.isRequired,
};

export default Prefooter;
