import React from "react";
import Default from "./Logo";
import Icon from "./LogoIcon";
import Sticker from "./LogoSticker";
import Wordmark from "./LogoWordmark";

/* eslint-disable react/prop-types */

const Logo = ({ variant = "logo", ...rest }, ref) => {
  switch (variant) {
    case "logo":
      return <Default {...rest} ref={ref} />;
    case "icon":
      return <Icon {...rest} ref={ref} />;
    case "sticker":
      return <Sticker {...rest} ref={ref} />;
    case "wordmark":
      return <Wordmark {...rest} ref={ref} />;

    default:
      return <Default {...rest} ref={ref} />;
  }
};

export default React.forwardRef(Logo);
