import React, { useState } from "react";
import AccordionContext from "./AccordionContext";
import AccordionSummary from "./AccordionSummary";
import AccordionDetails from "./AccordionDetails";

/* eslint-disable react/prop-types */

const Accordion = ({
  expanded = -1,
  setExpanded,
  speed = "normal",
  children,
}) => {
  const [internalExpanded, setInternalExpanded] = useState(expanded);

  return (
    <AccordionContext.Provider
      value={{
        expanded: setExpanded ? expanded : internalExpanded,
        setExpanded: setExpanded ?? setInternalExpanded,
        speed,
      }}
    >
      {children}
    </AccordionContext.Provider>
  );
};

Accordion.Summary = AccordionSummary;

Accordion.Details = AccordionDetails;

export default Accordion;
