import React from "react";
import classNames from "classnames";
import { useDimensions } from "@hooks";

/* eslint-disable react/prop-types */

const HeightTransitioner = ({
  className,
  innerClassName,
  initialHeight,
  speed = "normal",
  disabled = false,
  children,
}) => {
  const [ref, { height = initialHeight }] = useDimensions();

  return (
    <div className={className}>
      <div
        className={classNames({
          "overflow-hidden transition-[height] ease-in-out": !disabled,
          "duration-500": speed === "normal",
          "duration-300": speed === "fast",
          "duration-700": speed === "slow",
        })}
        style={{ height: disabled ? undefined : height }}
      >
        <div ref={ref} className={innerClassName}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default HeightTransitioner;
