import React from "react";

/* eslint-disable react/prop-types */

const LogoSticker = ({ width, height, stroke, fill, ...rest }, ref) => {
  return (
    <svg
      {...rest}
      ref={ref}
      width={width ?? height}
      height={width ?? height}
      viewBox="0 0 181 181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M178.07 90.66C178.07 139.262 138.672 178.66 90.07 178.66C41.4684 178.66 2.0700073 139.262 2.0700073 90.66C2.0700073 42.0584 41.4684 2.660004 90.07 2.660004C138.672 2.660004 178.07 42.0584 178.07 90.66Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="4"
      />
      <path
        d="M54.52 138.7C52.6241 138.846 50.7505 138.214 49.33 136.95C48.2225 135.686 47.6805 134.024 47.83 132.35V132.16C47.83 126.62 55.36 122.16 62.29 119.16C62.46 112.07 66.13 98.39 71.69 84.01C58.62 89.17 51.59 94.27 51.59 103.95C51.59 112.37 46.81 113.28 44.76 113.28C42.21 113.28 37.92 111.91 37.92 102.77C37.92 79.14 57.85 72.31 77.12 65.7L80.21 64.64C87.77 49.48 102.54 24.91 118.87 24.91C130.18 24.91 133.19 33.91 133.19 39.22C133.19 52.9 120.12 65.15 94.32 75.64C93.2 76.1 92.08 76.54 90.95 76.97C83.65 91.8 79.1 105.4 78.51 114.03C85.3211 112.434 92.2852 111.583 99.28 111.49C122.81 111.49 129.08 119.11 129.08 123.61C128.928 127.666 125.569 130.862 121.51 130.81C120.024 130.696 118.569 130.324 117.21 129.71C111.687 127.568 105.792 126.548 99.87 126.71C77.47 126.71 67.87 132.33 62.17 135.71C59.33 137.38 57.09 138.7 54.52 138.7ZM118.17 40.23C115.83 40.51 111.06 44.32 104.35 53.95C112.64 49.01 117.72 44.02 118.17 40.23Z"
        fill={fill}
      />
      <path
        d="M118.87 26.8C128.87 26.8 131.35 34.58 131.35 39.27C131.35 54.73 113.12 66.05 93.63 73.99C92.29 74.53 90.96 75.05 89.63 75.55C82.27 90.37 76.63 106.17 76.63 115.88C76.63 116.08 76.55 116.27 76.5 116.47C83.9523 114.532 91.6107 113.498 99.31 113.39C120.22 113.39 127.31 119.63 127.31 123.66C127.17 126.705 124.628 129.083 121.58 129.02C118.5 129.02 113.95 124.91 99.94 124.91C66.77 124.91 61.26 136.91 54.59 136.91C49.45 136.91 49.74 133.61 49.74 132.22C49.74 128.14 55.9 123.8 64.6 120.33C64.34 119.97 64.2 119.74 64.2 119.74C64.2 112.74 68.35 97.26 75.01 80.89C61.01 86.03 49.82 91.51 49.82 104.04C49.82 107.26 49.08 111.52 44.82 111.52C40.56 111.52 39.82 106.68 39.82 102.86C39.82 79.1 60.68 73.48 81.57 66.22C91.87 45.38 105.48 26.84 118.92 26.84L118.87 26.8ZM98.76 59.1C110.76 53.1 120.05 45.76 120.04 39.7C120.04 38.82 119.61 38.37 118.43 38.37C113.8 38.37 106.21 47.14 98.76 59.1ZM118.87 23.1C109.78 23.1 95.51 30.1 78.87 63.18L76.5 63.99C56.64 70.8 36.08 77.85 36.08 102.8C36.08 113.95 42.15 115.15 44.76 115.15C46.76 115.15 53.44 114.35 53.44 103.98C53.44 96.29 58.66 91.71 68.44 87.38C63.94 99.69 60.95 111.13 60.44 117.96C50.81 122.23 45.92 126.96 45.92 132.16V132.34C45.7511 134.501 46.4738 136.636 47.92 138.25C49.6846 139.866 52.032 140.693 54.42 140.54C57.49 140.54 60.05 139.04 63.01 137.3C68.93 133.83 77.87 128.58 99.77 128.58C105.473 128.426 111.15 129.408 116.47 131.47C118.032 132.165 119.704 132.578 121.41 132.69C126.508 132.785 130.721 128.737 130.83 123.64C130.83 117.2 122.54 109.64 99.18 109.64C92.9062 109.693 86.6527 110.363 80.51 111.64C81.65 103.37 85.83 91.42 92.19 78.38C93.1 78.03 94.02 77.67 94.91 77.3C121.47 66.5 134.91 53.67 134.91 39.17C134.91 31.17 129.91 23.01 118.74 23.01L118.87 23.1Z"
        fill={stroke}
      />
      <path
        d="M52 156.24C51.1186 155.533 50.6101 154.46 50.62 153.33C50.62 153.07 50.62 152.65 50.62 152.07C50.62 151.49 50.62 151.07 50.62 150.77C50.6318 150.008 50.8607 149.266 51.28 148.63C51.6991 148.02 52.2861 147.544 52.97 147.26C54.5799 146.644 56.3602 146.644 57.97 147.26C58.6539 147.544 59.241 148.02 59.66 148.63C60.0861 149.263 60.3157 150.007 60.32 150.77C60.32 151.32 60.32 151.77 60.32 152.07C60.32 152.37 60.32 152.81 60.32 153.33C60.339 154.462 59.8287 155.538 58.94 156.24C56.8262 157.586 54.1238 157.586 52.01 156.24H52ZM56.24 154.13C56.4483 153.882 56.5554 153.564 56.54 153.24C56.54 152.68 56.54 152.27 56.54 152.01C56.54 151.73 56.54 151.33 56.54 150.8C56.5559 150.479 56.4485 150.164 56.24 149.92C56.0286 149.724 55.7481 149.62 55.46 149.63C55.177 149.615 54.9008 149.72 54.7 149.92C54.4842 150.161 54.3727 150.477 54.39 150.8C54.39 151.06 54.39 151.47 54.39 152.01C54.39 152.55 54.39 152.96 54.39 153.24C54.3746 153.564 54.4817 153.882 54.69 154.13C55.1342 154.517 55.7958 154.517 56.24 154.13Z"
        fill={stroke}
      />
      <path
        d="M62.89 156.99C62.8192 156.914 62.7799 156.814 62.78 156.71V147.33C62.7814 147.229 62.8207 147.133 62.89 147.06C62.9625 146.983 63.064 146.939 63.17 146.94H70.48C70.586 146.939 70.6875 146.983 70.76 147.06C70.8331 147.13 70.8731 147.228 70.87 147.33V149.54C70.8746 149.645 70.8347 149.746 70.76 149.82C70.684 149.891 70.5839 149.93 70.48 149.93H66.33V151.01H70.19C70.2943 151.007 70.3951 151.047 70.4688 151.121C70.5426 151.195 70.5828 151.296 70.58 151.4V153.59C70.5846 153.695 70.5447 153.796 70.47 153.87C70.394 153.941 70.2939 153.98 70.19 153.98H66.33V156.71C66.3308 156.816 66.2873 156.917 66.21 156.99C66.1376 157.06 66.0408 157.1 65.94 157.1H63.17C63.0661 157.1 62.966 157.061 62.89 156.99Z"
        fill={stroke}
      />
      <path
        d="M76 160.32C75.8956 160.222 75.8375 160.084 75.84 159.94V147.18C75.8349 147.036 75.8934 146.897 76 146.8C76.097 146.693 76.236 146.635 76.38 146.64H82.22C83.8044 146.547 85.3735 146.994 86.67 147.91C87.7347 148.765 88.3289 150.076 88.27 151.44C88.2826 152.232 88.0823 153.012 87.69 153.7C87.3072 154.354 86.772 154.907 86.13 155.31L88.56 159.81C88.596 159.878 88.6165 159.953 88.6201 160.03C88.6217 160.147 88.5746 160.259 88.49 160.34C88.411 160.421 88.3032 160.468 88.19 160.47H84.14C83.9391 160.481 83.7407 160.421 83.58 160.3C83.4501 160.207 83.3495 160.078 83.29 159.93L81.59 156.16H80.78V159.93C80.7825 160.074 80.7245 160.212 80.62 160.31C80.5232 160.411 80.3898 160.469 80.25 160.47H76.38C76.2384 160.473 76.1015 160.419 76 160.32ZM82.22 152.42C82.4767 152.435 82.7254 152.328 82.89 152.13C83.0545 151.927 83.1397 151.671 83.13 151.41C83.1338 151.139 83.0535 150.873 82.9 150.65C82.7466 150.429 82.4888 150.304 82.22 150.32H80.78V152.42H82.22Z"
        fill={stroke}
      />
      <path
        d="M91.72 160.32C91.6194 160.219 91.562 160.083 91.56 159.94V147.18C91.5614 147.04 91.6191 146.907 91.72 146.81C91.8185 146.706 91.9565 146.647 92.1 146.65H102.23C102.518 146.66 102.75 146.892 102.76 147.18V149.97C102.75 150.258 102.518 150.49 102.23 150.5H96.3V151.8H101.8C101.938 151.8 102.071 151.854 102.17 151.95C102.274 152.048 102.333 152.186 102.33 152.33V154.9C102.332 155.041 102.274 155.175 102.17 155.27C102.075 155.373 101.94 155.431 101.8 155.43H96.3V156.67H102.39C102.53 156.671 102.663 156.729 102.76 156.83C102.864 156.929 102.923 157.066 102.92 157.21V159.99C102.923 160.134 102.864 160.271 102.76 160.37C102.663 160.471 102.53 160.529 102.39 160.53H92.1C91.9499 160.515 91.8125 160.439 91.72 160.32Z"
        fill={stroke}
      />
      <path
        d="M107.64 159.36C106.43 158.389 105.739 156.911 105.77 155.36C105.77 155 105.77 154.42 105.77 153.62C105.77 152.82 105.77 152.21 105.77 151.84C105.749 150.282 106.455 148.803 107.68 147.84C109.053 146.88 110.706 146.401 112.38 146.48C113.493 146.467 114.6 146.65 115.65 147.02C116.597 147.34 117.438 147.914 118.08 148.68C118.738 149.487 119.092 150.499 119.08 151.54C119.082 151.657 119.035 151.769 118.95 151.85C118.867 151.93 118.755 151.973 118.64 151.97H114.64C114.477 151.983 114.315 151.941 114.18 151.85C114.05 151.729 113.96 151.573 113.92 151.4C113.819 151.069 113.616 150.779 113.34 150.57C113.068 150.401 112.751 150.317 112.43 150.33C112.041 150.306 111.659 150.439 111.37 150.7C111.08 151.041 110.936 151.483 110.97 151.93C110.97 152.29 110.97 152.84 110.97 153.57C110.97 154.3 110.97 154.87 110.97 155.25C110.939 155.699 111.082 156.144 111.37 156.49C111.778 156.819 112.319 156.931 112.824 156.793C113.329 156.654 113.737 156.281 113.92 155.79C113.95 155.615 114.034 155.455 114.16 155.33C114.302 155.238 114.471 155.196 114.64 155.21H118.64C118.756 155.211 118.868 155.257 118.95 155.34C119.035 155.421 119.082 155.533 119.08 155.65C119.091 156.688 118.737 157.696 118.08 158.5C117.441 159.272 116.599 159.85 115.65 160.17C114.599 160.537 113.493 160.716 112.38 160.7C110.696 160.784 109.031 160.313 107.64 159.36Z"
        fill={stroke}
      />
    </svg>
  );
};

export default React.forwardRef(LogoSticker);
