import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Text from "./Text";

const getTag = sliceType => {
  switch (sliceType) {
    case "input_field":
      return "input";
    case "textarea":
      return "textarea";
    default:
      return "input";
  }
};

const Input = ({
  sliceType,
  label,
  required,
  onChange,
  onFocus,
  onBlur,
  hasError,
  preset,
  className: _className,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const input = useRef(null);

  const toggleFocus = () => {
    if (!isFocused && onFocus) {
      onFocus();
    } else if (isFocused && onBlur) {
      onBlur();
    }
    setIsFocused(!isFocused);
  };

  const handleChange = event => {
    if (onChange) {
      onChange(event);
    }
  };

  const className = classNames(
    {
      "w-full": true,
      "text-gray-light": preset === "dark",
      "text-gray": preset === "light",
    },
    _className
  );

  const Tag = getTag(sliceType);

  const inputClassName = classNames({
    "w-full block font-sans outline-none px-3 text-sm truncate border-b-1 border-gray-5 rounded-none": true,
    "h-12": Tag === "input",
    "py-3": Tag === "textarea",
    "bg-gray-5 focus:bg-gray-4": preset === "light",
    "bg-gray-3 focus:bg-gray-2": preset === "dark",
    "border-red": hasError,
  });

  return (
    <div className={className}>
      {label && (
        <Text preset="label">
          {label}
          {required && <span className="text-tertiary-blue">*</span>}
        </Text>
      )}

      <Tag
        className={inputClassName}
        ref={input}
        onChange={handleChange}
        onFocus={toggleFocus}
        onBlur={toggleFocus}
        required={required}
        {...rest}
      />
    </div>
  );
};

Input.propTypes = {
  sliceType: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  hasError: PropTypes.bool,
  preset: PropTypes.oneOf(["dark", "light"]),
  className: PropTypes.string,
};

Input.defaultProps = {
  label: null,
  required: false,
  onChange: null,
  onFocus: null,
  onBlur: null,
  hasError: false,
  preset: "dark",
  className: "",
};

export default Input;
