import { createContext, useContext } from "react";

const AccordionContext = createContext({
  expanded: -1,
  setExpanded: () => null,
  speed: "normal",
});

export const useAccordionContext = () => useContext(AccordionContext);

export default AccordionContext;
