import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import classNames from "classnames";

const AppLink = ({ to, target, className, children, style, onClick }) => {
  const handleClick = e => {
    if (onClick) {
      onClick(e);
    }
  };

  if (target === "_blank") {
    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={classNames(className, "active:opacity-75")}
        style={style}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      className={classNames(className, "active:opacity-75")}
      style={style}
      to={to}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};

AppLink.propTypes = {
  to: PropTypes.string.isRequired,
  target: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  onClick: PropTypes.func,
};

AppLink.defaultProps = {
  className: "",
  style: {},
  target: "_self",
  onClick: null,
};

export default AppLink;
