import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const presets = {
  h1: "text-xl2 sm:text-xl3 uppercase leading-tight",
  h2: "text-xl1-5 sm:text-xl2 leading-tight",
  h3: "font-bold text-base sm:text-lg leading-tight",
  p: {
    default: "leading-firm",
    lg: "text-lg sm:text-xl leading-firm",
    sm: "text-sm leading-tight",
  },
  tagline: "text-lg sm:text-xl",
  label: "text-xs uppercase leading-none",
  span: "",
  button: "",
};

const Text = ({
  tag,
  preset,
  variant,
  wrap,
  className: _className,
  children,
}) => {
  const Tag = tag || preset;

  let presetClassName;
  const hasVariants = typeof presets[preset] === "object";
  if (hasVariants) {
    if (presets[preset][variant]) {
      presetClassName = presets[preset][variant];
    } else {
      presetClassName = presets[preset].default;
    }
  } else {
    presetClassName = presets[preset];
  }

  return (
    <Tag
      className={classNames(
        {
          [presetClassName]: true,
          [`whitespace-${wrap}`]: wrap !== "none",
        },
        _className
      )}
    >
      {children}
    </Tag>
  );
};

Text.propTypes = {
  preset: PropTypes.oneOf(Object.keys(presets)).isRequired,
  tag: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  wrap: PropTypes.oneOf(["no-wrap", "pre", "pre-wrap", "none"]),
};

Text.defaultProps = {
  tag: null,
  variant: "",
  className: "",
  wrap: "pre-wrap",
};

export default Text;
