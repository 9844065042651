import React from "react";

/* eslint-disable react/prop-types */

const Logo = (props, ref) => (
  <svg
    ref={ref}
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 800 285"
    fill="currentColor"
    style={{
      overflow: "visible",
    }}
    {...props}
  >
    {/* Legends */}
    <g transform="translate(-141.79 -152.8)">
      <path d="M195,355.62c0-39.41,60.56-202.82,119.52-202.82,21.78,0,27.23,17,27.23,27.24,0,33.75-39.78,58.46-82.34,75.78-46.35,18.84-95.81,26.59-95.81,65.52,0,7.05-1.6,16.34-10.89,16.34s-10.89-10.57-10.89-18.9c0-55.59,52.3-65.69,100.93-83.47,38.12-13.94,74.33-36.61,74.33-54.31,0-1.92-1-2.88-3.52-2.88-25.32,0-91.32,120.15-91.32,169.17C213.88,386.06,195,355.62,195,355.62Z" />
      <path d="M163.41,382.86c0-19.23,62.64-41,108.3-41s61,13.61,61,22.43c0,6.4-5.77,11.69-12.49,11.69s-16.67-9-47.27-9c-72.41,0-84.42,26.11-99,26.11C162.77,393.11,163.41,385.9,163.41,382.86Z" />
      <path d="M309.28,293.22s.12,1.69,12,1.69c13.09,0,61.51-17.06,61.51-51,0-6-2.16-24.51-25.47-24.51-27.51,0-75.21,47.58-75.21,83.15,0,16.58,12.49,31,33.88,31,31,0,49-29.08,61-29.08,0,0,18.26-31.72,7.21-31.72-16.7,0-42.3,35.8-68.49,35.8-3.61,0-9.13-.48-9.13-7.69,0-10.21,28.71-56.47,48.54-56.47,1.2,0,1.92.72,1.92,2.41,0,4.44-17.78,25.23-34.12,25.23-5.77,0-13.46-1.68-14.18-6.25s-9.61,22.35-9.61,22.35Z" />
      <path d="M499,293.22s.12,1.69,12,1.69c13.1,0,61.52-17.06,61.52-51,0-6-2.16-24.51-25.47-24.51-27.52,0-75.22,47.58-75.22,83.15,0,16.58,12.5,31,33.89,31,31,0,49-29.08,61-29.08,0,0,18.27-31.72,7.21-31.72-16.7,0-42.29,35.8-68.48,35.8-3.61,0-9.13-.48-9.13-7.69,0-10.21,28.71-56.47,48.54-56.47,1.2,0,1.92.72,1.92,2.41,0,4.44-17.78,25.23-34.12,25.23-5.77,0-13.46-1.68-14.18-6.25S489,288.18,489,288.18Z" />
      <path d="M458.11,268.63c0-21.86-13.46-24.27-17.06-24.27-19.95,0-52.15,44.46-52.15,50.95,0,1.68.88,2.64,1.69,2.64,17.3,0,59-57.43,93.47-57.43,18.51,0-36,53.59-36,53.59-13.7,0-36.13,29.55-59.84,29.55-19,0-23.07-12.89-23.07-22,0-30.92,47.1-80.35,74.5-80.35s36.28,24.28,36.28,24.28Z" />
      <path d="M484.55,227.54c9.61,0,11.05,6.25,11.05,9.61,0,11.78-24.27,26-47.82,78.83-20.93,47-50.16,91.31-82.67,91.31-14.42,0-28.59-10.09-28.59-27.63s25.71-47.82,41.09-47.82c7.21,0,11.77,5.52,11.77,10.81,0,10.81-27.55,19.46-27.55,35.32,0,1.93.48,4.33,4.57,4.33C403.8,382.3,452.83,227.54,484.55,227.54Z" />
      <path d="M577.44,308.12c0-16.1,48.22-66.24,48.22-77.78,0-5.28-1.2-11.53-10.82-11.53-16.1,0-66.24,83.07-66.24,96.76,0,5.29,2.16,18,17.06,18,14.66,0,19.91-13.94,30.76-25,20.67-21.06,48-47.5,52.55-47.5,3.84,0-26.68,28.6-26.68,49.51a17.7,17.7,0,0,0,17.3,18c18.75,0,43.74-30.28,54.8-30.28,0,0,9.85-24.27,2.4-24.27-12.74,0-33.4,20.42-39.65,20.42s31-32.44,31-51.18c0-13-10.82-17.78-19.71-17.78-24.51,0-95.8,66-96,87.15Z" />
      <path d="M783.7,246.92s-9.37-25.47-31.48-25.47c-32.68,0-77.38,55.51-77.38,80.26,0,10.82,5.29,25,24,25,20,0,46.38-24.27,57.92-35.81s28.11-31.48,28.11-52.39c0-15.38-58.87,62-83.63,62-8.17,0,32.93-56.47,51.43-56.47,11.29,0,13.22,21.39,13.22,32.92Z" />
      <path d="M845.94,162.82c-13,0-107.42,105.25-107.42,143.46,0,12.26,3.13,27.16,21.87,27.16,17.06,0,32.86-16.52,47.66-31.65,10.86-11.1,29.16-27.23,29.16-33.16,0-5.52-1.44-11.77-9.61-11.77-12,0-54.47,49.06-61.44,49.06-1.69,0-1.93-1.8-1.93-4.21,0-11.77,31.71-55.37,58.88-87.23,16.31-19.12,33.88-33,33.88-40.13C857,171,856.75,162.82,845.94,162.82Z" />
      <path d="M865.4,260.29c0-3.67,4-6.91,4-8,0-1.29-1.48-1.48-3.84-1.48-7.52,0-21.63,7.51-21.63,11.53,0,6.4,43.74,6.08,43.74,31.72C887.67,320.22,847,334,829.2,334s-36-9.62-36-23.71,7.69-20.83,18.57-20.83,16.82,6.4,16.82,13.94c0,1.76-.1,3.36-.23,4.8a33.08,33.08,0,0,0,7.92.81c13.3,0,24.51-8.82,24.51-13.15,0-8.17-46-5.77-46-32,0-31.08,55.27-39.25,72.4-39.25,10.74,0,23.55,5.13,23.55,19.85,0,16.52-25.15,26.61-33.65,26.61S865.4,265,865.4,260.29Z" />
    </g>

    <g transform="translate(-625 -67.5) scale(1.5)">
      <path
        transform="translate(0, -11)"
        d="M657.678,210.729v5.077c0,2.858-0.711,5.246-2.134,7.159c-1.424,1.916-3.287,3.274-5.592,4.078
        c-2.308,0.807-5.024,1.207-8.155,1.207s-5.848-0.4-8.154-1.207c-2.305-0.804-4.167-2.162-5.592-4.078
        c-1.422-1.913-2.136-4.329-2.136-7.241v-4.913c0-4.189,1.476-7.326,4.42-9.406c2.946-2.081,6.768-3.121,11.462-3.121
        c4.697,0,8.52,1.04,11.463,3.121C656.207,203.484,657.678,206.595,657.678,210.729 M637.446,210.037v6.459
        c0,1.104,0.391,1.951,1.172,2.543c0.782,0.592,1.844,0.888,3.18,0.888c1.34,0,2.397-0.302,3.183-0.907
        c0.782-0.607,1.174-1.461,1.174-2.564v-6.377c0-1.103-0.392-1.957-1.174-2.563c-0.785-0.604-1.843-0.907-3.183-0.907
        c-1.336,0-2.397,0.296-3.18,0.889C637.836,208.088,637.446,208.935,637.446,210.037"
      />
      <path
        transform="translate(0, -11)"
        d="M665.364,198.326h27.15c0.311,0,0.583,0.113,0.812,0.341c0.228,0.228,0.34,0.498,0.34,0.812v6.488
        c0,0.312-0.112,0.585-0.34,0.811c-0.229,0.229-0.501,0.343-0.812,0.343h-16.776v2.731h14.001c0.312,0,0.583,0.114,0.812,0.34
        c0.226,0.23,0.341,0.5,0.341,0.813v6.445c0,0.315-0.115,0.585-0.341,0.812c-0.229,0.227-0.501,0.342-0.812,0.342h-14.001v8.453
        c0,0.313-0.115,0.581-0.344,0.811c-0.225,0.228-0.498,0.342-0.811,0.342h-9.22c-0.314,0-0.583-0.114-0.812-0.342
        c-0.228-0.229-0.342-0.497-0.342-0.811v-27.578c0-0.313,0.114-0.584,0.342-0.812C664.781,198.439,665.049,198.326,665.364,198.326
        "
      />
      <path
        d="M756.675,226.277c0.082,0.164,0.125,0.391,0.125,0.678s-0.135,0.567-0.404,0.834
        c-0.267,0.268-0.585,0.399-0.955,0.399h-14.012c-1.233,0-2.096-0.512-2.588-1.542l-5.433-12.219h-5.185v12.096
        c0,0.453-0.168,0.844-0.495,1.172c-0.329,0.33-0.721,0.493-1.172,0.493h-13.33c-0.454,0-0.845-0.163-1.174-0.493
        c-0.327-0.328-0.493-0.719-0.493-1.172v-39.869c0-0.453,0.166-0.842,0.493-1.172c0.329-0.329,0.72-0.494,1.174-0.494h24.007
        c5.636,0,10.103,1.338,13.392,4.012c3.293,2.676,4.939,6.316,4.939,10.924c0,5.596-2.16,9.648-6.48,12.158L756.675,226.277z
         M728.224,196.467v6.544h7.715c0.903,0,1.604-0.298,2.097-0.896c0.495-0.596,0.742-1.347,0.742-2.253
        c0-0.904-0.236-1.697-0.708-2.375c-0.477-0.679-1.187-1.02-2.131-1.02H728.224z"
      />
      <path
        d="M780.002,216.155h24.626c0.45,0,0.844,0.165,1.173,0.492c0.329,0.331,0.492,0.722,0.492,1.175v8.701
        c0,0.454-0.163,0.844-0.492,1.172c-0.329,0.33-0.723,0.493-1.173,0.493h-39.004c-0.456,0-0.847-0.163-1.174-0.493
        c-0.329-0.328-0.494-0.718-0.494-1.172v-39.869c0-0.452,0.165-0.841,0.494-1.172c0.327-0.329,0.718-0.494,1.174-0.494h38.388
        c0.45,0,0.841,0.165,1.17,0.494c0.328,0.331,0.493,0.72,0.493,1.172v8.703c0,0.452-0.165,0.842-0.493,1.171
        c-0.329,0.332-0.72,0.494-1.17,0.494h-24.01v3.888h20.614c0.451,0,0.84,0.168,1.174,0.494c0.328,0.33,0.493,0.721,0.493,1.175
        v8.021c0,0.452-0.165,0.844-0.493,1.172c-0.334,0.331-0.723,0.495-1.174,0.495h-20.614V216.155z"
      />
      <path
        d="M831.411,201.92v9.338c0,1.595,0.566,2.822,1.698,3.676c1.131,0.855,2.611,1.284,4.443,1.284
        c1.831,0,3.158-0.271,3.983-0.817c0.822-0.545,1.51-1.342,2.065-2.394c0.555-1.05,1.284-1.575,2.193-1.575h13.329
        c0.37,0,0.685,0.132,0.954,0.395c0.27,0.265,0.401,0.577,0.401,0.94c0,3.765-1.809,7.207-5.431,10.324
        c-1.809,1.538-4.219,2.784-7.219,3.735c-3.007,0.95-6.543,1.424-10.618,1.424c-4.073,0-7.784-0.611-11.137-1.834
        c-3.356-1.224-6.082-3.228-8.18-6.018c-2.095-2.787-3.146-6.206-3.146-10.259v-7.103c0-4.049,1.052-7.48,3.146-10.289
        c3.952-5.215,10.553-7.82,19.812-7.82c3.743,0,7.116,0.476,10.123,1.427c3,0.949,5.41,2.195,7.219,3.733
        c3.622,3.117,5.431,6.558,5.431,10.32c0,0.365-0.132,0.68-0.401,0.943s-0.584,0.395-0.954,0.395h-13.329
        c-0.909,0-1.639-0.526-2.193-1.576c-0.556-1.051-1.243-1.846-2.065-2.393c-0.825-0.543-2.152-0.814-3.983-0.814
        c-1.832,0-3.312,0.427-4.443,1.283C831.977,199.102,831.411,200.326,831.411,201.92"
      />
    </g>
  </svg>
);

export default React.forwardRef(Logo);
