import React from "react";
import PropTypes from "prop-types";
import Text from "./Text";
import Button from "./Button";

const Plan = ({ numberTeams, subscriptions, term, className = "" }) => {
  const { price, braintreePlanId } = subscriptions.find(
    subscription => subscription.term === term
  );
  const appUrl = `https://app.legendsofrec.com/signup?planId=${braintreePlanId}`;

  return (
    <div className={`${className} p-4`}>
      <div className="flex flex-col items-center bg-gray-4 rounded shadow p-6 transform hover:scale-105 transition duration-150">
        <div className="bg-gray-3 rounded-full px-3 mb-4">
          <Text preset="label" className="text-blue">
            {numberTeams} Teams
          </Text>
        </div>
        <Text preset="h3" className="mb-8">
          ${price} / {term === "Monthly" ? "month" : "year"}
        </Text>
        <Button
          size="sm"
          textColor="gray"
          onClick={() => window.open(appUrl, "_self")}
          text="Start Free Trial"
        />
      </div>
    </div>
  );
};

Plan.propTypes = {
  numberTeams: PropTypes.number.isRequired,
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      term: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      braintreePlanId: PropTypes.string.isRequired,
    })
  ).isRequired,
  term: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Plan;
