import { getTailwindUtility } from "@utils";
import { useEffect, useMemo, useState } from "react";
import useWindowSize from "./useWindowSize";

const screens = getTailwindUtility("screens");

function getBreakpoints(outerWidth) {
  const breakpoints = {
    current: "default",
  };

  Object.entries(screens).forEach(([key, val]) => {
    if (typeof val === "object") {
      return;
    }

    const num = val.replace("px", "");
    breakpoints[key] = num <= outerWidth;
    breakpoints.current = num <= outerWidth ? key : breakpoints.current;
  });

  return breakpoints;
}

function useBreakpoints() {
  const [hydrated, setHydrated] = useState(false);
  const { innerWidth } = useWindowSize();

  useEffect(() => {
    setHydrated(true);
  }, []);

  const breakpoints = useMemo(
    () => getBreakpoints(innerWidth),
    [innerWidth, hydrated]
  );

  return { ...breakpoints, hydrated };
}

export default useBreakpoints;
