import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveByPrefix from "../dataResolvers/resolveByPrefix";
import resolveImageData from "../dataResolvers/resolveImageData";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query HomePageQuery {
    prismicHomePage {
      id
      data {
        hero_heading
        hero_subheading
        hero_tagline
        hero_screenshot {
          url
          alt
          dimensions {
            width
            height
          }
        }
        grid_paper_pencil {
          url
          alt
          dimensions {
            width
            height
          }
        }
        grid_rows {
          feature
          is_lor_feature
          is_paper_pencil_feature
        }
        features {
          heading
          subheading
          description {
            html
          }
          image {
            url
            alt
            dimensions {
              width
              height
            }
          }
          poster {
            url
            alt
            dimensions {
              width
              height
            }
          }
          cta_type
          cta_link {
            url
          }
        }
        testimonials {
          text {
            html
          }
          name
          title
          photo {
            url
            alt
            dimensions {
              width
              height
            }
          }
        }
        plans_heading
        plans_subheading
        plans_disclaimer {
          html
        }
        contact_heading
        contact_subheading
        prefooter_image {
          url
          alt
          dimensions {
            width
            height
          }
        }
        meta_title
        meta_description
        meta_keywords
        meta_image {
          url
          alt
          dimensions {
            width
            height
          }
        }
        body {
          __typename
          ... on PrismicHomePageBodyGallery {
            id
            slice_type
            primary {
              heading
              description {
                html
              }
              image {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
            }
          }
        }
        faq {
          id
          document {
            ... on PrismicFaq {
              id
              data {
                faq_pairs {
                  question
                  answer {
                    text
                    html
                    raw
                  }
                  image {
                    url
                    thumbnails
                    dimensions {
                      height
                      width
                    }
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicGlobals {
      id
      data {
        logo {
          url
          alt
          dimensions {
            width
            height
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicHomePage, prismicGlobals }) => {
  const prefixes = [
    "hero_",
    "grid_",
    "testimonials_",
    "plans_",
    "contact_",
    "prefooter_",
    "meta_",
  ];

  const { prefixed, unPrefixed } = resolveByPrefix(
    prismicHomePage.data,
    prefixes
  );

  const { hero, grid, prefooter, meta } = prefixed;
  const { testimonials, features, body, faq } = unPrefixed;

  const resolved = {
    ...prefixed,
    logos: [resolveImageData({ image: prismicGlobals.data.logo })],
    hero: {
      ...hero,
      screenshot: resolveImageData({ image: hero.screenshot }),
    },
    grid: {
      ...grid,
      paperPencil: resolveImageData({ image: grid.paper_pencil }),
      rows: grid.rows.map((item, idx) => ({
        ...item,
        isLorFeature: item.is_lor_feature === "True",
        isPaperPencilFeature: item.is_paper_pencil_feature === "True",
        id: idx,
      })),
    },
    features: features.map((item, idx) => ({
      ...item,
      id: idx,
    })),
    testimonials: testimonials.map((item, idx) => ({
      ...item,
      id: idx,
      photo: item.photo ? resolveImageData({ image: item.photo }) : null,
    })),
    gallery: body.map(item => ({
      id: item.id,
      heading: item.primary.heading,
      image: resolveImageData({ image: item.primary.image }),
    })),
    prefooter: {
      image: resolveImageData({ image: prefooter.image }),
    },
    faq: faq.document.data.faq_pairs.map((item, idx) => ({
      ...item,
      id: idx,
    })),
    meta: {
      ...meta,
      image: resolveImageData({ image: meta.image }),
    },
  };

  return keysToCamel(resolved);
};

const useHomePageData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useHomePageData;
