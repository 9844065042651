import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useCallback,
} from "react";
import PropTypes from "prop-types";

const Iframe = forwardRef(
  ({ src, className = "", style = {}, Loading }, forwardedRef) => {
    // If no ref forwarded, then register one for the iframe
    const iframe = forwardedRef || useRef();

    const [iframeLoaded, setIframeLoaded] = useState(false);

    const handleIframeLoad = useCallback(() => {
      setIframeLoaded(true);
    }, [setIframeLoaded, src]);

    useEffect(() => {
      iframe.current.addEventListener("load", handleIframeLoad, true);

      return () => {
        iframe.current?.removeEventListener("load", handleIframeLoad);
      };
    }, [src, setIframeLoaded]);

    return (
      <>
        <iframe
          ref={iframe}
          title="iframe"
          className={className}
          width="100%"
          height="100%"
          src={src}
          style={{
            ...style,
            opacity: iframeLoaded ? 1 : 0,
          }}
        />

        {!iframeLoaded && !!Loading && <Loading />}
      </>
    );
  }
);

Iframe.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  Loading: PropTypes.func,
};

export default Iframe;
