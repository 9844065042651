import React from "react";
import classNames from "classnames";
import { useHeaderData } from "@queries";
import { scrollToEl } from "@utils";
import AppLink from "../AppLink";

const Header = () => {
  const { links } = useHeaderData();
  return (
    <header className="flex items-center p-4 bg-gray-2">
      {links.map(({ id, link, title }, idx) => (
        <AppLink
          key={id}
          to={link?.url || `#${title.split(" ").join("-")}`}
          target={link?.target}
          className={classNames({
            "flex items-center text-md capitalize text-blue": true,
            "ml-auto": idx === 0,
            "ml-4": idx > 0,
          })}
          onClick={
            link?.url
              ? undefined
              : e => {
                  e.preventDefault();
                  scrollToEl(
                    document.getElementById(title.split(" ").join("-"))
                  );
                }
          }
        >
          {title}
        </AppLink>
      ))}
    </header>
  );
};

export default Header;
