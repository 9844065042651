import React from "react";
import classNames from "classnames";
import Plus from "@svg/plus.svg";
import Minus from "@svg/minus.svg";
import { useAccordionContext } from "./AccordionContext";

/* eslint-disable react/prop-types */

const AccordionSummary = ({ index, children, className }) => {
  const { expanded, setExpanded } = useAccordionContext();
  const isExpanded = expanded === index;
  const Icon = isExpanded ? Minus : Plus;

  return (
    <button
      type="button"
      className={classNames(
        className,
        "outline-none focus:outline-none transition duration-150 active:opacity-75"
      )}
      onClick={() => {
        setExpanded(isExpanded ? -1 : index);
      }}
    >
      <div className="flex items-center justify-between">
        <div className="pr-4 text-left">{children}</div>
        <Icon className="ml-auto h-4 w-4 shrink-0 sm:h-5 sm:w-5" />
      </div>
    </button>
  );
};

export default AccordionSummary;
