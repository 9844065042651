import React from "react";
import classNames from "classnames";
import { useAccordionContext } from "./AccordionContext";
import HeightTransitioner from "../HeightTransitioner";

/* eslint-disable react/prop-types */

const AccordionDetails = ({ index, children, className }) => {
  const { expanded, speed } = useAccordionContext();
  const isExpanded = expanded === index;

  return (
    <HeightTransitioner speed={speed}>
      <div
        className={classNames({
          "overflow-visible transition-opacity ease-in-out": true,
          "h-0 opacity-0": !isExpanded,
          "h-auto opacity-100": isExpanded,
          "duration-500": speed === "normal",
          "duration-300": speed === "fast",
          "duration-700": speed === "slow",
        })}
      >
        <div className={className}>{children}</div>
      </div>
    </HeightTransitioner>
  );
};

export default AccordionDetails;
