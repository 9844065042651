import React from "react";

/* eslint-disable react/prop-types */

const LogoWordmark = ({ width = "100%", height = "100%", ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 769 255"
    {...rest}
  >
    <path d="M53.21 202.82C53.21 163.41 113.77 0 172.73 0C194.51 0 199.96 17 199.96 27.24C199.96 60.99 160.18 85.7 117.62 103.02C71.27 121.86 21.81 129.61 21.81 168.54C21.81 175.59 20.21 184.88 10.92 184.88C1.63 184.88 0.0299988 174.31 0.0299988 165.98C0.0299988 110.39 52.33 100.29 100.96 82.51C139.08 68.57 175.29 45.9 175.29 28.2C175.29 26.28 174.29 25.32 171.77 25.32C146.45 25.32 80.45 145.47 80.45 194.49C72.09 233.26 53.21 202.82 53.21 202.82Z" />
    <path d="M21.62 230.06C21.62 210.83 84.26 189.06 129.92 189.06C175.58 189.06 190.92 202.67 190.92 211.49C190.92 217.89 185.15 223.18 178.43 223.18C171.71 223.18 161.76 214.18 131.16 214.18C58.75 214.18 46.74 240.29 32.16 240.29C20.98 240.31 21.62 233.1 21.62 230.06ZM167.49 140.42C167.49 140.42 167.61 142.11 179.49 142.11C192.58 142.11 241 125.05 241 91.11C241 85.11 238.84 66.6 215.53 66.6C188.02 66.6 140.32 114.18 140.32 149.75C140.32 166.33 152.81 180.75 174.2 180.75C205.2 180.75 223.2 151.67 235.2 151.67C235.2 151.67 253.46 119.95 242.41 119.95C225.71 119.95 200.11 155.75 173.92 155.75C170.31 155.75 164.79 155.27 164.79 148.06C164.79 137.85 193.5 91.59 213.33 91.59C214.53 91.59 215.25 92.31 215.25 94C215.25 98.44 197.47 119.23 181.13 119.23C175.36 119.23 167.67 117.55 166.95 112.98C166.23 108.41 157.34 135.33 157.34 135.33L167.49 140.42ZM357.21 140.42C357.21 140.42 357.33 142.11 369.21 142.11C382.31 142.11 430.73 125.05 430.73 91.11C430.73 85.11 428.57 66.6 405.26 66.6C377.74 66.6 330.04 114.18 330.04 149.75C330.04 166.33 342.54 180.75 363.93 180.75C394.93 180.75 412.93 151.67 424.93 151.67C424.93 151.67 443.2 119.95 432.14 119.95C415.44 119.95 389.85 155.75 363.66 155.75C360.05 155.75 354.53 155.27 354.53 148.06C354.53 137.85 383.24 91.59 403.07 91.59C404.27 91.59 404.99 92.31 404.99 94C404.99 98.44 387.21 119.23 370.87 119.23C365.1 119.23 357.41 117.55 356.69 112.98C355.97 108.41 347.21 135.38 347.21 135.38L357.21 140.42Z" />
    <path d="M316.32 115.83C316.32 93.97 302.86 91.56 299.26 91.56C279.31 91.56 247.11 136.02 247.11 142.51C247.11 144.19 247.99 145.15 248.8 145.15C266.1 145.15 307.8 87.72 342.27 87.72C360.78 87.72 306.27 141.31 306.27 141.31C292.57 141.31 270.14 170.86 246.43 170.86C227.43 170.86 223.36 157.97 223.36 148.86C223.36 117.94 270.46 68.51 297.86 68.51C325.26 68.51 334.14 92.79 334.14 92.79L316.32 115.83Z" />
    <path d="M342.76 74.74C352.37 74.74 353.81 80.99 353.81 84.35C353.81 96.13 329.54 110.35 305.99 163.18C285.06 210.18 255.83 254.49 223.32 254.49C208.9 254.49 194.73 244.4 194.73 226.86C194.73 209.32 220.44 179.04 235.82 179.04C243.03 179.04 247.59 184.56 247.59 189.85C247.59 200.66 220.04 209.31 220.04 225.17C220.04 227.1 220.52 229.5 224.61 229.5C262.01 229.5 311.04 74.74 342.76 74.74ZM435.65 155.32C435.65 139.22 483.87 89.08 483.87 77.54C483.87 72.26 482.67 66.01 473.05 66.01C456.95 66.01 406.81 149.08 406.81 162.77C406.81 168.06 408.97 180.77 423.87 180.77C438.53 180.77 443.78 166.83 454.63 155.77C475.3 134.71 502.63 108.27 507.18 108.27C511.02 108.27 480.5 136.87 480.5 157.78C480.42 162.458 482.194 166.977 485.436 170.35C488.678 173.723 493.123 175.675 497.8 175.78C516.55 175.78 541.54 145.5 552.6 145.5C552.6 145.5 562.45 121.23 555 121.23C542.26 121.23 521.6 141.65 515.35 141.65C509.1 141.65 546.35 109.21 546.35 90.47C546.35 77.47 535.53 72.69 526.64 72.69C502.13 72.69 430.84 138.69 430.64 159.84L435.65 155.32Z" />
    <path d="M641.91 94.12C641.91 94.12 632.54 68.65 610.43 68.65C577.75 68.65 533.05 124.16 533.05 148.91C533.05 159.73 538.34 173.91 557.05 173.91C577.05 173.91 603.43 149.64 614.97 138.1C626.51 126.56 643.08 106.62 643.08 85.71C643.08 70.33 584.21 147.71 559.45 147.71C551.28 147.71 592.38 91.24 610.88 91.24C622.17 91.24 624.1 112.63 624.1 124.16L641.91 94.12Z" />
    <path d="M704.15 10.02C691.15 10.02 596.73 115.27 596.73 153.48C596.73 165.74 599.86 180.64 618.6 180.64C635.66 180.64 651.46 164.12 666.26 148.99C677.12 137.89 695.42 121.76 695.42 115.83C695.42 110.31 693.98 104.06 685.81 104.06C673.81 104.06 631.34 153.12 624.37 153.12C622.68 153.12 622.44 151.32 622.44 148.91C622.44 137.14 654.15 93.54 681.32 61.68C697.63 42.56 715.2 28.68 715.2 21.55C715.21 18.2 714.96 10.02 704.15 10.02Z" />
    <path d="M723.61 107.49C723.61 103.82 727.61 100.58 727.61 99.49C727.61 98.2 726.13 98.01 723.77 98.01C716.25 98.01 702.14 105.52 702.14 109.54C702.14 115.94 745.88 115.62 745.88 141.26C745.88 167.42 705.21 181.2 687.41 181.2C669.61 181.2 651.41 171.58 651.41 157.49C651.41 143.4 659.1 136.66 669.98 136.66C680.86 136.66 686.8 143.06 686.8 150.6C686.8 152.36 686.7 153.96 686.57 155.4C689.168 155.988 691.827 156.26 694.49 156.21C707.79 156.21 719 147.39 719 143.06C719 134.89 673 137.29 673 111.06C673 79.98 728.27 71.81 745.4 71.81C756.14 71.81 768.95 76.94 768.95 91.66C768.95 108.18 743.8 118.27 735.3 118.27C726.8 118.27 723.61 112.2 723.61 107.49Z" />
  </svg>
);

export default React.forwardRef(LogoWordmark);
