import React from "react";

/* eslint-disable react/prop-types */

const LogoIcon = (props, ref) => (
  <svg
    ref={ref}
    width="100%"
    height="100%"
    fill="currentColor"
    strokeWidth="2"
    viewBox="0 0 95 114"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M61.28,88.98 C53.5810607,89.0935502 45.9233375,90.127561 38.47,92.06 C38.47,91.87 38.56,91.68 38.6,91.48 C38.6,81.77 44.26,65.96 51.6,51.15 C52.94,50.64 54.27,50.15 55.6,49.58 C75.09,41.65 93.32,30.33 93.32,14.87 C93.32,10.18 90.82,2.4 80.84,2.4 C67.4,2.4 53.79,20.93 43.49,41.78 C22.6,49.04 1.74,54.66 1.74,78.42 C1.74,82.24 2.47,87.08 6.74,87.08 C11.01,87.08 11.74,82.82 11.74,79.59 C11.74,67.06 22.92,61.59 36.93,56.44 C30.27,72.81 26.12,88.31 26.12,95.3 C26.12,95.3 26.26,95.53 26.52,95.88 C17.82,99.36 11.66,103.69 11.66,107.77 C11.66,109.17 11.37,112.47 16.51,112.47 C23.18,112.47 28.69,100.47 61.86,100.47 C75.86,100.47 80.42,104.59 83.5,104.59 C86.5475216,104.652895 89.0896304,102.274937 89.23,99.23 C89.24,95.23 82.19,88.98 61.28,88.98 Z M80.43,13.98 C81.61,13.98 82.04,14.42 82.04,15.3 C82.04,21.37 72.76,28.71 60.76,34.7 C68.21,22.8 75.8,13.99 80.43,13.99 L80.43,13.98 Z"
      fillRule="nonzero"
    />
  </svg>
);

export default React.forwardRef(LogoIcon);
