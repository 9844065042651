import React from "react";
import { useFooterData } from "@queries";
import { useAppState } from "@state/state";
import classNames from "classnames";
import AppLink from "../AppLink";

const Footer = () => {
  const { links } = useFooterData();
  const [{ layout }] = useAppState();

  return (
    <footer
      className={classNames({
        "absolute left-0 right-0 z-100 px-4 flex flex justify-center sm:justify-end": true,
        "bottom-4": !layout.previewMode,
        "bottom-16": layout.previewMode,
      })}
    >
      <div className="p-2 md:p-3 text-center bg-gray-5/50 backdrop-blur-md rounded-lg">
        <div className="text-sm md:mr-3 text-white">
          &copy; {new Date().getFullYear()} Legends of Rec, Inc
        </div>

        <div>
          {links.map(({ id, link, title }, idx) => (
            <React.Fragment key={id}>
              <AppLink
                to={link.url}
                target={link.target}
                className="mx-1 text-tertiary-blue text-sm"
              >
                {title}
              </AppLink>

              {idx < links.length - 1 && (
                <span className="text-tertiary-blue">|</span>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
