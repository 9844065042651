import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Text from "./Text";
import Image from "./Image/ImageWrap";
import imageProps from "./Image/propTypes";
import Logo from "./Logo";
import getTailwindUtility from "../utils/getTailwindUtility";

const Hero = ({ heading, tagline, screenshot }) => {
  const [heroImageLoaded, setHeroImageLoaded] = useState(false);

  const colors = getTailwindUtility("colors");

  return (
    <div className="max-w-lg mx-auto">
      <div className="md:flex md:flex-row-reverse md:justify-center">
        <div className="py-2 sm:py-8 md:py-10 text-center md:text-left">
          <div className="flex flex-col items-center text-center mb-2">
            <Logo
              variant="sticker"
              width={100}
              stroke={colors.blue}
              fill={colors.gray.DEFAULT}
              className="fill-white stroke-blue"
            />
            <Logo width={300} className="text-gray-6 my-6" />
            {heading && (
              <Text preset="h1" className="my-3">
                {heading}
              </Text>
            )}
            {tagline && (
              <div className="mt-2 max-w-sm sm:px-6 mb-4 md:mb-0">
                <Text
                  preset="tagline"
                  tag="span"
                  className="text-tertiary-blue"
                  wrap="pre"
                >
                  {tagline}
                </Text>
              </div>
            )}
          </div>
        </div>

        <div className="w-full h-96 md:h-auto md:w-2/5 lg:w-1/3 relative overflow-hidden flex justify-center">
          <div className="relative w-full max-w-[380px]">
            <div
              className={classNames({
                "absolute left-6 top-0 right-6 bottom-0 transform transition-transform": true,
                "translate-y-1/2": !heroImageLoaded,
              })}
              style={{
                transitionDuration: "1s",
                transitionTimingFunction: "cubic-bezier(0.165, 0.84, 0.44, 1)",
              }}
            >
              <Image
                image={screenshot}
                objectFit="contain"
                onLoad={() => setHeroImageLoaded(true)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  heading: PropTypes.string,
  tagline: PropTypes.string,
  screenshot: imageProps.props.isRequired,
};

Hero.defaultProps = {
  tagline: null,
};

export default Hero;
